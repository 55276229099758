import mixpanel from 'mixpanel-browser';
import React from 'react';
import MixpanelContext from './src/services/tracking';

export const wrapRootElement = ({ element }) => {
  mixpanel.init(process.env.MIXPANEL_TOKEN);
  return (
    <MixpanelContext.Provider value={mixpanel}>
      {element}
    </MixpanelContext.Provider>
  );
};
