exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app/[...].js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-approve-new-admin-js": () => import("./../../../src/pages/approve-new-admin.js" /* webpackChunkName: "component---src-pages-approve-new-admin-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-helpcenter-js": () => import("./../../../src/pages/helpcenter.js" /* webpackChunkName: "component---src-pages-helpcenter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-set-password-js": () => import("./../../../src/pages/set-password.js" /* webpackChunkName: "component---src-pages-set-password-js" */),
  "component---src-pages-text-me-the-app-js": () => import("./../../../src/pages/text-me-the-app.js" /* webpackChunkName: "component---src-pages-text-me-the-app-js" */),
  "component---src-pages-update-password-js": () => import("./../../../src/pages/update-password.js" /* webpackChunkName: "component---src-pages-update-password-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

